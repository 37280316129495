.contact-field {
    line-height: 1.5;
    height: 36px;
    padding: 8px;
    width: 100%;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    border-color: lightgray;
    border-image: none;
    margin-top: 8px;
}

label {
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
}

.contact-field-large {
    min-height: 200px;
}

.submit {
    background-color:white;
    border: none;
    color: black;
    padding: 16px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border: 2px solid lightgray;
}

.submit:hover {
    background-color: lightgray;
}